<template>
  <div>
    <v-breadcrumbs :items="[
      { text: 'Solutions', exact: true, to: { name: 'solutions' } },
      { text: 'Security & Privacy', disabled: true },
    ]" />
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Data Privacy and Security</h1>
      <p>
        We take the privacy and security of your employee data very serious. We work with organization who have put highest levels of security in place to ensure safety of their employee data. 
      </p>
        
      <p>
        Some of our customers are active in healthcare or are supplier to the US government and require a fitness platform to meet their strict privacy standards to safeguard secure processing of their employee information.
        We work with our customers to ensure our data security and privacy requirements are covered and can put a custom Data Processing Agreement in place for additional tailor-made data processing agreements.
      </p>
    </div>


    <div :class="`mt-10 ${$vuetify.breakpoint.xs?'':'Zindent'}`">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-lock" title="GDPR &amp; CCPA compliant" text="All our hosting providers and sub-processors are GDPR compliant and are either SOC2 Type 2 or ISO 27001 certified (or more) and have strict security measures in place."/>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-user-lock" title="Custom DPA possible" text="We can put a custom Data Processing Agreement in place to outline how employee data is processed."/>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-globe" title="Choose your datacenter" text="Choose the geopolitical region where you want to store your employee information."/>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-shield-check" title="Security and privacy checklists" text="We can work with your legal and compliancy team to ensure our solution meets the strict privacy and security constraints put in place by your organization."/>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-user-tag" title="Role-based access" text="Assign roles to your co-admins to safe-guard control of access to sensitive settings and data."/>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-heartbeat" title="Data access controls" text="Control who may (or may not) access employee fitness and health information. Ensure only selected users have access to sensitive employee/fitness data."/>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4">
          <BigButton elevated icon="fa-comments" title="Schedule a meeting" text="Reach out and schedule a meeting to see how we can help your organization with a fitness challenge." :to="{name:'contact'}"/>
        </v-col> -->
      </v-row>
    </div>

    <div class="green white--text my-10 py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Get in touch</h2>
            <h1 class="subtitle">Schedule a meeting to discuss the security and privacy measures we put in place to secure your data</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4" color="white" href="/contact">Contact Us</v-btn>
              <v-btn class="ml-4 mb-4" color="white" outlined href="/request-demo">Request Free Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="">
      <h2 class="subtitle">Solutions for Connected Fitness Challenges</h2>
      <v-list elevation="2" class="my-8 py-0">
        <v-list-item v-for="(item, idx) in navData['solutions-menu-cofi'].filter(x => x.highlight)" :key="idx" :to="item.to" >
          <v-list-item-icon>
            <v-icon size="25">fadl fa {{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action>
            <v-icon size="25">fal fa-chevron-circle-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>    


  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import BigButton from "@/components/BigButton.vue";
import siteData from '@/data/site.json'
import navData from '@/data/nav.json'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
    BigButton,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      navData: navData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>